// /* You can add global styles to this file, and also import other style files */
body {
    //  background-color: #010A43 !important;
}
.ng-toast {
    color: Black !important;
  }
  /* Custom class for the Swal popup */
.swal2-popup {
    background-color: #010A43 !important;
    color: white !important;
  }

  /* You can also customize other parts of the popup as needed */
  .swal2-title {
    color: white !important;
  }

  .swal2-content {
    color: white !important;
  }

  .swal2-actions .swal2-confirm {
    background-color: #faad39 !important;
  }

  .swal2-actions .swal2-cancel {
    background-color: #eb4034 !important;
  }
  .cdk-overlay-container, .cdk-overlay-backdrop {
    position: fixed;
    top: 30%;
    left: 40%;
    width: 100%;
    height: 100%;
  }

  .custom-dialog-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mat-mdc-dialog-actions {
    justify-content: start;
    color: #ffffff !important;
    background: #192259;
    border-bottom: 1px solid #464E8A;
    border-left: 1px solid #464E8A;
    border-right: 1px solid #464E8A;
    border-top: none !important;


}
.mat-mdc-dialog-container .mdc-dialog__title {
  color: #ffffff !important;
  background: #192259;
  border-bottom: none !important;
    border-left: 1px solid #464E8A;
    border-right: 1px solid #464E8A;
    border-top: 1px solid #464E8A;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  color: #ffffff !important;
  background: #192259;
  border-left: 1px solid #464E8A;
  border-right:1px solid #464E8A ;
}
  .custom-dialog-container .mat-dialog-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 768px) {
    .custom-dialog-container .mat-dialog-container {
      width: 90%;
      max-width: 300px; // Adjust the max-width as needed for smaller screens
    }
  }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
